const PNG_ENCODE = 'data:image/png;base64,';

export const RATIO_2x3 = '2x3';
export const RATIO_3x4 = '3x4';
export const RATIO_1x1 = '1x1';
export const RATIO_4x3 = '4x3';
export const RATIO_16x9 = '16x9';
export const RATIO_21x9 = '21x9';
export const RATIO_10x3 = '10x3';
export const RATIO_32x9 = '32x9';
export const SUPPORTED_RATIOS = [RATIO_3x4, RATIO_1x1, RATIO_4x3, RATIO_16x9];

export const shapeFixer = (ratio = RATIO_1x1) => {
	if (ratio === RATIO_4x3) {
		return PNG_ENCODE + 'iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAQAAAAe/WZNAAAADklEQVR42mNkgAJGDAYAAFEABCaLYqoAAAAASUVORK5CYII=';
	} else if(ratio === RATIO_2x3) {
		return PNG_ENCODE + 'iVBORw0KGgoAAAANSUhEUgAAAAIAAAADCAQAAAAT4xYKAAAADklEQVR42mNkAAJGOAEAAC0ABNxMS2YAAAAASUVORK5CYII='
	} else if (ratio === RATIO_3x4) {
		return PNG_ENCODE + 'iVBORw0KGgoAAAANSUhEUgAAAAMAAAAECAQAAADhJE2MAAAADklEQVR42mNkAANGbBQAAGIABUN3+8IAAAAASUVORK5CYII=';
	} else if (ratio === RATIO_16x9) {
		return PNG_ENCODE + 'iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAQAAACRI2S5AAAAEElEQVR42mNkIAAYRxWAAQAG9gAKqv6+AwAAAABJRU5ErkJggg==';
	} else if (ratio === RATIO_21x9) {
		return PNG_ENCODE + 'iVBORw0KGgoAAAANSUhEUgAAABUAAAAJCAQAAAB3Cq/9AAAAEUlEQVR42mNkIBowjiqliVIACRIACvsH5W4AAAAASUVORK5CYII=';
	} else if (ratio === RATIO_10x3) {
		return PNG_ENCODE + 'iVBORw0KGgoAAAANSUhEUgAAAAoAAAADCAQAAAAANFb+AAAAD0lEQVR42mNkwAIYiRYEAAC9AASyXfztAAAAAElFTkSuQmCC';
	} else if (ratio === RATIO_32x9) {
		return PNG_ENCODE + 'iVBORw0KGgoAAAANSUhEUgAAACAAAAAJCAQAAAD50OaBAAAAE0lEQVR42mNkoBAwjhowagAQAAANtgAKaS3tcQAAAABJRU5ErkJggg==';
	} else {
		return PNG_ENCODE + 'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
	}
};

export const WIDTH_150 = '150';
export const WIDTH_320 = '320';
export const WIDTH_450 = '450';
export const WIDTH_640 = '640';
export const WIDTH_800 = '800';
export const WIDTH_1024 = '1024';
export const WIDTH_1920 = '1920';
export const WIDTH_3840 = '3840';

export const SUPPORTED_WIDTHS = [WIDTH_150, WIDTH_320, WIDTH_640, WIDTH_800, WIDTH_1024, WIDTH_1920, WIDTH_3840];

export const getTumbnail = (imageObj = {}, ratio = RATIO_1x1, maxWidth = WIDTH_1024) => {
	const rightRatio = (SUPPORTED_RATIOS.indexOf(ratio) > -1) ? ratio : RATIO_1x1,
		rightWidth = (SUPPORTED_WIDTHS.indexOf(maxWidth) > -1) ? maxWidth : WIDTH_1024,
		imageInnerObj = imageObj[ratio] || {};

	if (imageInnerObj) {
		const subWidths = SUPPORTED_WIDTHS.slice(0, SUPPORTED_WIDTHS.indexOf(rightWidth) + 1);

		return subWidths.reduce((img = '', width = '') => {
			return imageInnerObj[width] || img;
		}, '');
	} else {
		return shapeFixer(rightRatio);
	}
}